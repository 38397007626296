exports.components = {
  "component---src-templates-404-page-js": () => import("./../../../src/templates/404-page.js" /* webpackChunkName: "component---src-templates-404-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-contact-thanks-js": () => import("./../../../src/templates/contact-thanks.js" /* webpackChunkName: "component---src-templates-contact-thanks-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-online-store-js": () => import("./../../../src/templates/online-store.js" /* webpackChunkName: "component---src-templates-online-store-js" */),
  "component---src-templates-price-page-js": () => import("./../../../src/templates/price-page.js" /* webpackChunkName: "component---src-templates-price-page-js" */),
  "component---src-templates-simple-template-js": () => import("./../../../src/templates/simple-template.js" /* webpackChunkName: "component---src-templates-simple-template-js" */)
}

